<!-- 提款 -->
<template>
<div class="viewsPages">
        <div class="views-Withdraw">
            <!-- <Tab @tab="tab" :tabIndex="1" :arr="['会员存款', '会员提款']" /> -->
            <!-- 主账户 -->
            <div class="basic-balance">
                <div class="basic-actonet">
                    <span>
                        {{ $t("accountPage.balance") }}
                        ({{ $t("accountPage.element") }})
                    </span>
                    <span class="balance">{{ balance }}</span>
                    <i class="refresh-btn refresh-btn-lg" :class="{'rotate': rotate}" @click="getPlatformBalance(true)"></i>
                </div>
                <div class="balance-btn cursorPointer" @click="oneKeyBalance">{{$t("userPage.text102")}}</div>
            </div>
            <!-- 游戏平台 -->
            <div class="basic-platform">
                <div class="basic-platform-item" 
                    v-for="(item, i) in platformBalances" 
                    :key="i" 
                    v-show="i <= total"
                    :class="{ 'moneyBg': !item.operation }">
                    <span>{{ item.vendorName }}</span>
                    <div class="balance" v-if="item.operation">￥{{ item.totalMoney }}</div>
                    <div v-if="!item.operation">{{$t("userPage.text103")}}</div>
                </div>
                <div class="basic-platform-more cursorPointer" v-if="platformBalances.length > 0" @click="onMore">
                    <img loading="lazy"
                        v-lazy="require('@/assets/images/my/daw.png')"
                        class="moreImg"
                        :class=" condition ? 'moreImgup' : 'moreImgdaw'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { _getPlatformBalance, _oneKeyBalance } from '@/core/api/modules/user'
import { mapGetters } from 'vuex'
import { Message,} from 'element-ui'
export default {
    components: {
    },
    filters:{
    },
    computed: {
        ...mapGetters([
            'userData',
            'userPlatformBalance',
        ]),
        balance () { // 主账户数据
            return this.userData.totalBalance || '0.00'
        },
        platformBalances () { // 游戏平台数据
            //return this.userPlatformBalance.filter(item => item.type != 1)
            return this.userPlatformBalance
        },
    },
    data () {
        return {
            showOrigin: { // 手续费相关源数据
                withdrawPayRate: 0, // 百分比
                handlingfee: 0, // 手续费
                administrativeCosts: 0, //行政费用
                discountDeduction: 0, // 优惠扣除
                withdrawType:0, //取款方式 0:区间任意金额，1:固定金额
                withdrawFixed:null, //固定金额
                singleMax:0,//固定最大金额
                singleMin:0,//固定最小金额
                digitRate:''
            },
            exchange:0,
            checkMoneyIndex: null,
            withdrawNum:false, 
            condition:false,
            total: 18,//游戏平台
            drawing:true,//取款下一步
            rotate:false,
            rotate1:false,
        }
    },
    mounted () {
        this.getPlatformBalance()
    },
    methods: {
        // 更多游戏平台
        onMore() {
            this.condition = !this.condition;
            this.total = this.total == 18 ? this.userPlatformBalance.length : 18;
        },

        // 获取各平台余额
        getPlatformBalance (isRefresh) {
            this.rotate = true
            _getPlatformBalance().then(res => {
                if (res) {
                    if (isRefresh) Message.success(this.$t("userPage.text121"))
                    this.rotate = false
                }
            })
        },
        // 一键归集
        oneKeyBalance () {
            _oneKeyBalance().then(res => {
                if (res) return this.getPlatformBalance(true)
            })
        },
    }
}
</script>

<style scoped lang="scss">
$imgUrl: '~@/assets/images/my/';
.views-Withdraw{
    width: 1110px;
    margin:0 auto;
    .basic-balance {
        margin: 20px 0;
        padding: 20px 10px;
        border-radius: 10px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        color: #6f6f6f;
        font-size: 20px;
        background:#dfeaf4;
        .basic-actonet{
            flex: 1;
        }
        span{
            vertical-align: middle;
        }
        .balance{
            color: #ce06cd;
            margin: 0 10px;
            font-weight: 700;
        }
        .balance-btn{
            text-align: center;
            font-size: 16px;
            color: #fff;
            height: 50px;
            width: 30%;
            line-height: 50px;
            background: #ce06cd;
            box-shadow: 0 9px 9px 0 rgba(44, 44, 68, 0.12);
            border-radius: 20px;
            margin-left: 33px;
            cursor: pointer;
        }
        .rotate {
            animation: refresh 2s linear infinite;
            -moz-animation: refresh 2s linear infinite;
            /* Firefox */
            -webkit-animation: refresh 2s linear infinite;
            /* Safari and Chrome */
            -o-animation: refresh 2s linear infinite;
            /* Opera */
        }
    }
    .basic-platform {
        display:grid;
        grid-template-columns: repeat(5,1fr);
        grid-gap: 20px 10px;
        margin:10px auto;
        min-height: 400px;
        border-radius: 10px;
        background:#dfeaf4;
        padding: 20px 10px;
        .basic-platform-item {
            display: block;
            background: #FFFFFF;
            padding: 15px 20px;
            margin: 0 0 10px;
            border-radius: 10px;
            &.moneyBg{
                background: linear-gradient(163deg,#412841,#ce06cd);
                color: #fff;
                .balance{
                    color: #fff;
                }
            }
            .balance{
                color: #ce06cd;
                font-size: 16px;
                font-weight: 700;
                margin: 0 10px;
            }
        }
        .basic-platform-more{
            padding: 15px 0;
            border-radius: 5px;
            border: 1px solid #ce06cd;
            background:#ce06cd;
            display:flex;
            align-items: center;
            text-align: center;
                margin: 0 0 10px;
            img{
                margin: auto;
            }
            .moreImgdaw{
                animation: Running 0.5s linear infinite alternate running;
            }
            .moreImgup{
                transform:rotateX(180deg);
            }
        }
    }
}
@keyframes Running{
    0%{
        transform:translateY(4px);
    }
    100%{
        transform:translateY(0px);
    }
}
.rotate1 {
    animation: refresh 1s linear infinite;
}
</style>